import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import {collaboratorsCollectionRef} from '../../services/fireref'
import { addDoc } from 'firebase/firestore'
import SideBarUpPages from '../../Components/SideBar/SideBarUpPages'
import '../pages.css'
import Countries from '../../libs/Countries.json'

function UpCollaborator() {



  //states pra pergar infos nos select e depois enviar

  const [collabType, setCollabType]=useState('');
  const [collabCategory, setCollabCategory]=useState('')

  //states para envio
  const [collabName, setCollabName]=useState('');
  const [collabEmail, setCollabEmail]=useState('');
  const [collabPhone, setCollabPhone]=useState('');
  const [collabCountry, setCollabCountry]=useState('');
  const [collabJurisdictions, setCollabJaurisdictions]=useState([]);
  const [collabStart, setCollabStart]=useState(new Date().getFullYear().toString());
  const [collabRecomendation, setCollabRecomendation]=useState('');
  const [collabComments, setCollabComments]=useState('');
  const [collabAccount, setCollabAccount]=useState('');
  const [collabNDA, setCollabNDA]=useState(false);
  const [collabNDADate, setCollabNDADate]=useState('');
  const [collabNDALink, setCollabNDALink]=useState('');
  const [collabContract, setCollabContract]=useState(false);
  const [collabContractDate, setCollabContractDate]=useState('');
  const [collabContractLink, setCollabContractLink]=useState('');

   //states upando
   const [uploading, setUploading] = useState(false);
   const navigate = useNavigate();

  //mudando estados para boleanos
  useEffect(()=>{
    if(collabNDA === 'true'){
      setCollabNDA(true)
    }
    if(collabNDA === 'false'){
      setCollabNDA(false)
    }
  },[collabNDA])

  useEffect(()=>{
    if(collabContract === 'true'){
      setCollabContract(true)
    }
    if(collabContract === 'false'){
      setCollabContract(false)
    }
  },[collabContract])
  //upando e mandando notificação google agenda
  
  const handleAdd = async(e)=>{
    e.preventDefault();
    setUploading(true);
    await addDoc( collaboratorsCollectionRef, {
      collabType,
      collabCategory,
      collabName,
      collabEmail,
      collabPhone,
      collabCountry,
      collabJurisdictions,
      collabStart,
      collabRecomendation,
      collabComments,
      collabAccount,
      collabNDA,
      collabNDADate,
      collabNDALink,
      collabContract,
      collabContractDate,
      collabContractLink 
      });
    //navigate('/');

    
    setUploading(false);
    navigate('/colaboradores')
}
  return (
    <div className='superContainer'>
        <SideBarUpPages/>
        <div className='pageHeader'>
            <div className="pageTitleCenter">
                <div className="pageTitle">Novo Colaborador</div>
            </div>
        </div>
            <div className="pagesContentup">
            <form className="form600" onSubmit={handleAdd}>
            <div className="linhas">
                <div className='clientName'>
                    <p>Nome:</p>
                    <input type="text" required placeholder='Nome' onChange={(e)=>{setCollabName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}} />
                </div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Email:</p>
                    <input type="email" required placeholder='Email' onChange={(e)=>{setCollabEmail(e.target.value)}} />
                </div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Telefone:</p>
                    <input type="text" placeholder='Telefone' onChange={(e)=>{setCollabPhone(e.target.value)}} />
                </div>
            </div>
            <div className="linhas">
                <div className='clientPhone'>
                    <p>País:</p>
                    <select required onChange={(e)=>{setCollabCountry(e.target.value)}}>
                    <option value="">Selecione</option>
                        {[...Countries].map((item, index)=>(
                        <option value={item.country} key={index} id={item.id}>{item.country}</option>
                        ))}
                    </select>
                </div>
                <div className='clientPhone'>
                    <p>Tipo:</p>
                    <select onChange={(e)=>{setCollabType(e.target.value)}} required>
                      <option value="">selecione</option>
                      <option value="analista">Analista</option>
                      <option value="fornecedor">Fornecedor</option>
                    </select>
                </div>
                <div className='clientPhone'>
                    <p>Calegoria:</p>
                    <select required onChange={(e)=>{setCollabCategory(e.target.value)}}>
                      {collabType !== 'analista' && collabType !== 'fornecedor' ?  <option value="">Escolha um tipo</option> : ''}
                      {collabType === 'analista'?
                        <>
                        <option value="">Selecione</option>
                        <option value="Líder de Projeto">Líder de Projeto</option>
                        <option value="Pesquisador">Pesquisador</option>
                        <option value="Pesquisador de Documentos">Pesquisador de Documentos</option>
                        <option value="Segurança">Segurança</option>
                        <option value="Tradutor/Revisor">Tradutor/Revisor</option>
                        
                        </>
                        : ''
                      }
                      {collabType === 'fornecedor'?
                        <>
                        <option value="">Selecione</option>
                        <option value="Tecnologia/Telecom">Tecnologia/Telecom</option>
                        <option value="Contabilidade">Contabilidade</option>
                        <option value="Escritório Virtual">Escritório Virtual</option>
                        <option value="Banco/Financeiro">Banco/Financeiro</option>
                        <option value="Documentos/Banco de Dados">Documentos/Banco de Dados</option>
                        <option value="Jurídico/Legais">Jurídico/Legais</option>                    
                        </>
                        : ''
                      }                       
                    </select>
                </div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Profissão:</p>
                    <input type="text" required placeholder='Área de Atuação' onChange={(e)=>{setCollabJaurisdictions(e.target.value.split(','))}} />
                </div>
            </div>
            <div className="linhas">
                <div className='clientStat'>
                    <p>Desde:</p>
                    <input type="text"  placeholder='AAAA' required defaultValue={collabStart}  onChange={(e)=>{setCollabStart(e.target.value)}}/>
                </div>
                <div className='clientName'>
                    <p>Referência/Origem:</p>
                    <input type="text" required placeholder='Referência/Origem' onChange={(e)=>{setCollabRecomendation(e.target.value)}} />
                </div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Comentários:</p>
                    <textarea required rows="6" placeholder='Comentários' onChange={(e)=>{setCollabComments(e.target.value)}}></textarea>
                </div>
            </div>
            <div className="linhaCentral">
              <div className="linhasTitle">DADOS PAGAMENTO</div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Dados de Pagamento:</p>
                    <textarea required rows="6" placeholder='Conta de Pagamento'onChange={(e)=>{setCollabAccount(e.target.value)}}></textarea>
                </div>
            </div>
            <div className="linhaCentral">
              <div className="linhasTitle">NDA</div>
            </div>
            <div className="linhas">
              <div className='clientPhone'>
                  <p>Tem NDA?</p>
                  <select onChange={(e)=>{setCollabNDA(e.target.value)}} required>
                    <option value="">Selecione</option>
                    <option value={true}>SIM</option>
                    <option value={false}>NÃO</option>
                  </select>
              </div>
                      
              <div className='clientPhone'>
                  <p>Data de Expiração:</p>
                  <input type="date" onChange={(e)=>{setCollabNDADate(new Date(e.target.value))}} required={collabNDA? true:false}/>
              </div>
              <div className='clientName'>
                  <p>Link do Documento:</p>
                  <input type="text" placeholder='Link do Documento' onChange={(e)=>{setCollabNDALink(e.target.value)}} required={collabNDA? true:false} />
              </div>
            </div>         
            <div className="linhaCentral">
              <div className="linhasTitle">CONTRATO</div>
            </div>
            <div className="linhas">
              <div className='clientPhone'>
                  <p>Tem Contrato?</p>
                  <select onChange={(e)=>{setCollabContract(e.target.value)}} required>
                    <option value="">Selecione</option>
                    <option value={true}>SIM</option>
                    <option value={false}>NÃO</option>
                  </select>
                  
              </div>
              <div className='clientPhone'>
                  <p>Data de Expiração:</p>
                  <input type="date" onChange={(e)=>{setCollabContractDate(new Date(e.target.value))}} required={collabContract? true:false} />
              </div>
              <div className='clientName'>
                  <p>Link do Documento:</p>
                  <input type="text" placeholder='Link do Documento' onChange={(e)=>{setCollabContractLink(e.target.value)}} required={collabContract? true:false}/>
              </div>
            </div>  



              <input type="submit" value="ADICIONAR COLABORADOR" />

              {uploading &&
                        <span>uploading...</span>
             }
            </form>
            </div>
              

    </div>
  )
}

export default UpCollaborator